import TagsStyles from './index.module.scss';

const Sort = {
	selectors: {
		label: TagsStyles.label,
		list: TagsStyles.list,
		open: TagsStyles.open
	},

	elements: {
		label: null as HTMLElement,
		list: null as HTMLElement
	},

	init() {
		this.toggle = this.toggle.bind(this);

		this.setElements();
		this.elements.label.addEventListener('click', this.toggle);
	},

	setElements() {
		this.elements.label = document.getElementsByClassName(this.selectors.label)[0];
		this.elements.list = document.getElementsByClassName(this.selectors.list)[0];
	},

	toggle() {
		const list = this.elements.label;
		const name = this.selectors.open;
		list.classList.toggle(name);
	}
}

document.addEventListener('ready', Sort.init.bind(Sort));
